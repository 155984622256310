.quest-waiting-badge {
  margin-left: 20px;
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.4rem;
  font-size: 0.9rem;
  min-width: 24px;
  text-align: center;
  line-height: 1;
}

.quest-student-notification-dot {
  margin-left: 10px;
  width: 14px;
  height: 14px;
  background-color: red;
  border-radius: 50%;
}
