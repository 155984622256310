/* AnnouncementsModal.css */

/* Modal container styling */
.announcements-modal {
  z-index: 1050;
}

.announcements-modal .modal-dialog {
  max-width: 600px;
}

/* Modal content styling */
.announcements-modal .modal-content {
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Modal header with matching gradient background */
.announcements-modal .modal-header {
  background: linear-gradient(135deg, #6d5dfb, #8c67f5);
  color: #fff;
  padding: 1rem 1.5rem;
  border-bottom: none;
}

.announcements-modal .modal-title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

/* Modal body styling matching the course page content */
.announcements-modal .modal-body {
  padding: 1.5rem;
  background-color: #fff;
}

/* Form label styling */
.announcements-modal .form-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
}

/* Modal footer styling consistent with CoursePage */
.announcements-modal .modal-footer {
  padding: 1rem 1.5rem;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

/* Modal drop animations */
@keyframes modalDropIn {
  from {
    transform: translateY(-10vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes modalDropOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-10vh);
    opacity: 0;
  }
}

.modal-drop-in {
  animation: modalDropIn 0.3s ease forwards;
}

.modal-drop-out {
  animation: modalDropOut 0.3s ease forwards;
}

/* Backdrop opacity (matches course page backdrop) */
.modal-backdrop {
  opacity: 0.8;
}

/* Styling for error alerts (consistent typography) */
.alert-danger {
  margin-bottom: 1rem;
  font-size: 0.9rem;
}
