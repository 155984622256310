/* welcome.css */
.welcome-message {
  margin-bottom: 2rem;
}
.welcome-message h2 {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  color: #2d3748;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
.welcome-message p {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-size: 1.375rem;
  color: #4a5568;
  margin: 0;
}

/* The "show inbox" round button */
.show-inbox-button {
  position: fixed;
  top: 5rem; /* Adjust so it doesn't overlap other elements */
  right: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  font-size: 1.25rem;
  cursor: pointer;
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition:
    background-color 0.2s ease,
    transform 0.2s ease;
}
.show-inbox-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
