.announcements-inbox {
  position: fixed;
  right: 0;
  top: 0;
  width: 320px;
  height: 100vh;
  background-color: #f8f9fa;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  padding: 1rem;
  z-index: 2000; /* above the .show-inbox-button */
  transition: transform 0.3s ease;
  transform: translateX(0);
}

.announcements-inbox.hidden {
  transform: translateX(100%);
}

.inbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.inbox-header h2 {
  font-size: 1.5rem;
  margin: 0;
  color: #333;
}

.announcements-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.announcement-card {
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0.75rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition:
    background-color 0.2s ease,
    box-shadow 0.2s ease;
}

.announcement-card:hover {
  background-color: #f1f1f1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.announcement-card.unread {
  border-left: 4px solid #007bff;
}

.announcement-card.read {
  opacity: 0.75;
}

.announcement-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.teacher-avatar {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 0.75rem;
  flex-shrink: 0;
}

.notification-dot {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: #dc3545;
  border-radius: 50%;
  border: 2px solid #ffffff;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}

.announcement-info {
  display: flex;
  flex-direction: column;
}

.course-name {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
}

.teacher-name,
.date-sent {
  font-size: 0.85rem;
  color: #555;
}

.announcement-message {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  color: #333;
  border-top: 1px solid #eee;
  padding-top: 0.5rem;
}

.announcement-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.announcement-actions button {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

.no-announcements {
  text-align: center;
  color: #666;
  margin-top: 1rem;
}

.inbox-error {
  color: red;
  text-align: center;
  margin-top: 1rem;
}

/* The "hide inbox" round button */
.inbox-toggle-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.2s ease,
    transform 0.2s ease;
}

.inbox-toggle-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
